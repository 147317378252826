  const handler = {
    get: function (target, name) {
      return name in target ? target[name] : null
    },
    set: function (obj, prop, value) {
      // valida que la propiedad este dentro del formulario o su configuración
      if(typeof obj.config[prop] == 'undefined' && typeof obj[prop] == 'undefined') {
        return true;
      }
      if ( ['_valid', '_erros', '_dirty'].indexOf(prop) >= 0) {
        obj[prop] = value;
        return true
      }
      // console.log(obj.config)
      const old = obj[prop]
      obj[prop] = value
      obj.value[prop] = value
      obj._dirty = true
      //obj.cmp.$emit('form:control:change', obj.value)
      obj.key++
      obj.emmit('change', { control: prop, old_value: old, new_value: value,  value: obj.value})
      // console.log('[ctrl change]', prop)
      return true
    }
  }

  class NgForm {
    cmp = null
    _dirty = false
    _valid = false
    _errors = []
    _inputs = []
    value = {}
    config = {}
    listeners = []
    key = 0

    constructor (config, component) {
      this.cmp = component
      this.config = config
    }

    getCtrolConfig (name) {
      return this.config[name]
    }
    
    setValid (val) {
      // console.log(val)
      this._valid = val;
      // this.emmit('change', { control: '_valid', new_value: val,  value: this.value})
    }
    
    pushError (err) {
      this._errors.push(err)
    }
    
    pushInput (input) {
      //console.log(this._inputs.map((item) => item.config.name))
      //console.log('push:', input.config.name)
      for (const inp of this._inputs) {
        if (inp.config.name == input.config.name) {
          return
        }
      }
      
      this._inputs.push(input)

    }

    getInput (name) {
      //console.log(this._inputs.map((item) => item.config.name))
      //console.log('push:', input.config.name)
      for (const inp of this._inputs) {
        if (inp.config.name == name) {
          return inp
        }
      }
      
      return null;

    }
    
    clean () {
      for (const i in this.value) {
        this[i] = null
      }
      console.log(this.value)
      this._valid = false
      this._dirty = false
      this.emmit('init', this.value)
    }
    checkInputs(){
        // console.log('***', this._inputs.length)
        for (const inp of this._inputs) {
            inp.testRules()
        }
    }
    
    setValue (value) {
      for (const i in value) {
        this[i] = value[i]
      }
      this._dirty = false
      //this.emmit('init', this.value)

    }
    
    emmit(event, data){
      //console.log(this.listeners)
      for (const l of this.listeners) {
        // console.log('emit changes...')
        if (l.event == event) {
          l.callback(data);
        }
      }
    }
    
    on(event, callback){
      this.listeners.push( { event, callback } )
    }

    init () {
    }
  }
  
export { NgForm, handler };