<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        sm="12"
    
      >
        <ng-form :form="form" >
          <div :key="form.key">
            {{ form.value }}
          </div>

          <v-container class="py-0">
            <v-row no-gutters>
              <ng-text
                  :form="form"
                  :options="form.getCtrolConfig('username')"
              />
              <ng-text
                  :form="form"
                  :options="form.getCtrolConfig('username2')"
              />
              <ng-chips
                  :form="form"
                  :options="form.getCtrolConfig('city')"
              />
              <ng-text
                  :form="form"
                  :options="form.getCtrolConfig('email')"
              />
              <ng-text
                  :form="form"
                  :options="form.getCtrolConfig('first_name')"
              />
              <ng-money
                  :form="form"
                  :options="form.getCtrolConfig('cost')"
              />
              <ng-check
                  :form="form"
                  :options="form.getCtrolConfig('is_boy')"
              />
              <ng-time
                  :form="form"
                  :options="form.getCtrolConfig('birthday')"
              />
              <ng-date-range
                  :form="form"
                  :options="form.getCtrolConfig('dates_range')"
              />
              <ng-select-options
                  :form="form"
                  :options="form.getCtrolConfig('day')"
              />
              <ng-select-multi
                  :form="form"
                  :options="form.getCtrolConfig('day2')"
              />
              <v-col cols="12">
                <v-btn v-on:click="clear()">Clear</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </ng-form>
    </v-col>

    <v-col
        cols="12"
        md="4"
    >
        <!--base-material-card
          class="v-card-profile"
          avatar="https://demos.creative-tim.com/vue-material-dashboard/img/marc.aba54d65.jpg"
        >
          <v-card-text class="text-center">
            <h6 class="display-1 mb-1 grey--text">
              CEO / CO-FOUNDER
            </h6>

            <h4 class="display-2 font-weight-light mb-3 black--text">
              Alec Thompson
            </h4>
            <p class="font-weight-light grey--text">
              Don't be scared of the truth because we need to restart the human foundation in truth And I love you like Kanye loves Kanye I love Rick Owens’ bed design but the back is...
            </p>

            <v-btn
              color="success"
              rounded
              class="mr-0"
            >
              Follow
            </v-btn>
          </v-card-text>
        </base-material-card-->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  import { NgForm, handler } from '@/components/gui/NgForm'

  export default {
    data () {
      return {
        form: null

      }
    },
    created () {
     /* this.$on ('form:control:change', (val) => {
        console.log(val)
        this.formControlsKey++
      }
      ) */
      const config = {
        username: {
          name: 'username',
          label: 'User name',
          validate: [ 'required' ],
          required: true,
          'prepend-inner-icon': 'mdi-at',
          keyup: 'hook_username_chage'
        },
        username2: {
          name: 'username2',
          label: 'User name 2'
        },
        city: {
          name: 'city',
          label: 'Ciudad',
          url: 'api/default/bus_ciudad/search'
        },
        first_name: {
          name: 'first_name',
          label: 'First name'
        },
        cost: {
          name: 'cost',
          label: 'Cost'
        },
        is_boy: {
          name: 'is_boy',
          label: 'Is Boy?'
        },
        email: {
          name: 'email',
          label: 'Email',
          show: 'hook_email_is_visible'
        },
        birthday: {
          name: 'birthday',
          label: 'Birthday',
          // depends_of: ['first_name'],
          disabled: 'hook_birthday_is_disabled'
        },
        'dates_range': {
          name: 'dates_range',
          label: 'Range',
          // depends_of: ['first_name'],
        },
        day: {
          name: 'day',
          label: 'Day',
          validate: [ 'required' ],
          required: true,
          change: 'hook_change_day',
          items: [ { id: 1, name: 'Lunes'}, { id: 2, name: 'Martes'}, { id: 3, name: 'Miercoles'} ]
        },
        day2: {
          name: 'day2',
          label: 'Day 2',
          validate: [ 'required' ],
          required: true,
          url: 'api/default/bus_pais/search'
         //  change: 'hook_change_day',
          // items: [ { id: 1, name: 'Lunes'}, { id: 2, name: 'Martes'}, { id: 3, name: 'Miercoles'}, { id: 4, name: 'Jueves'} ]
        }
      }
      const form = new NgForm(config, this)
      this.form = new Proxy(form, handler)
      setTimeout(() => {
        console.log('jijijiji') // birthday: '2020-01-15',
        this.form.setValue( { username: 'geyffer@gmail.com', email: 'jijijijij', cost: 550000, is_boy: true,
                            day: { id: 2, name: 'Martes'}, day2: [{ id: 'AND', name: 'Andorra'}],
                            city: [{ id: 2535, name: 'Aguascalientes'}] } )
      }, 1000)
      // this.form.init()
    },
    methods: {
      clear(){
        this.form.clean()
      }
    }
  }
</script>
